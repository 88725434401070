import { BrowserRouter, Routes, Route } from "react-router-dom";
import TechTalent from './Pages/TechTalent';
import MetricAgil from './Pages/MetricAgil'
import UXAnalysis from './Pages/UXAnalysis'
import ProjectManager from './Pages/ProjectManager'
import Tourism from "./Pages/Tourism";

import Navigator from './Components/Navigator/Navigator';
import Copyright from './Components/Copyright/Copyright';
import Footer from './Components/Footer/Footer';


function App() {
  return (
    <main>
      <Navigator />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TechTalent />} />
          <Route path="/tech-talent" element={<TechTalent />} />
          <Route path="/project-Manager" element={<ProjectManager />} />
          <Route path="/agile-metrics" element={<MetricAgil />} />
          <Route path="/ux-analysis" element={<UXAnalysis />} />
          <Route path="/tourism" element={<Tourism />} />
          <Route path="*" element={<h1>404</h1>}></Route>
        </Routes>
      </BrowserRouter>
      <Footer />
      <Copyright />
    </main>
  );
}

export default App;