//import img1 from '../../images/logoTechnology/1.svg'
import img2 from './images/logoTechnology/1.svg'
import img3 from './images/logoTechnology/3.svg'
//import img5 from '../../images/logoTechnology/android.svg'
import img6 from './images/logoTechnology/angular.svg'
import img7 from './images/logoTechnology/aws.svg'
import img8 from './images/logoTechnology/net.svg'
import img9 from './images/logoTechnology/flutter.svg'
//import img10 from '../../images/logoTechnology/go.svg'
import img11 from './images/logoTechnology/microsoft_azure-icon.svg'
import img12 from './images/logoTechnology/google_cloud-icon.svg'
//import img13 from '../../images/logoTechnology/icono-seguimiento.svg'
//import img14 from '../../images/logoTechnology/icono-talento-de-primera.svg'
//import img15 from '../../images/logoTechnology/icono-trazabilidad.svg'
//import img16 from '../../images/logoTechnology/laravel.svg'
import img17 from './images/logoTechnology/mongo.svg'
//import img18 from '../../images/logoTechnology/mysql.svg'
import img19 from './images/logoTechnology/node.svg'
//import img20 from '../../images/logoTechnology/php.svg'
import img21 from './images/logoTechnology/python.svg'
import img22 from './images/logoTechnology/react.svg'
//import img23 from '../../images/logoTechnology/sheet.svg'
//import img24 from '../../images/logoTechnology/shopify.svg'
//import img25 from '../../images/logoTechnology/star.svg'
//import img26 from '../../images/logoTechnology/user-white.svg'
//import img27 from '../../images/logoTechnology/user.svg'
//import img28 from '../../images/logoTechnology/vue.svg'
//import img29 from '../../images/logoTechnology/wordpress.svg'

export default function DataSlider() {
    const data =

    {
        "title": "TECNOLOGIAS",
        "slide": [

            {
                "img": img3,
                "alt": "Typescript"
            },

            {
                "img": img6,
                "alt": "Angular"
            },

            {
                "img": img22,
                "alt": "React"
            },
            {
                "img": img7,
                "alt": "AWS"
            },
            {
                "img": img11,
                "alt": "Azure"
            },
            {
                "img": img12,
                "alt": "Google Cloud"
            },





            {
                "img": img21,
                "alt": "Python"
            },

            {
                "img": img8,
                "alt": "NET"
            },


            {
                "img": img19,
                "alt": "NodeJS"
            },
            {
                "img": img17,
                "alt": "MongoDB"
            },

        ]
    }

    return data;
}


