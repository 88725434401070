import en from './en.json';
import es from './es.json';
import fr from './fr.json';

export default function DataBoxCards(code) {
    switch (code) {
        case 'es':
            return es;
        case 'en':
            return en;
        case 'fr':
            return fr;
        default:
            return es;
    }
}