import './i18n';
import { useTranslation } from 'react-i18next';

export default function Navigator() {
    const { t } = useTranslation();
    return (
        <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">

            <a className="navbar-brand logo-text " href="/">idataTrip</a>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-awesome fas fa-bars"></span>
                <span className="navbar-toggler-awesome fas fa-times"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="/tech-talent">{t('techTalen')} </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/project-Manager">{t('projectManager')} </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/ux-analysis">{t('Analisis UX')} </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/agile-metrics">{t('Metricas Agiles')} </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/tourism">{t('tourism')} </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link page-scroll" href="#contact">{t('contact')} </a>
                    </li>
                </ul>

                <span className="nav-item social-icons">
                    <span className="fa-stack">
                        <a href="https://www.facebook.com/profile.php?id=100090889806967&mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                            <i className="fas fa-circle fa-stack-2x"></i>
                            <i className="fab fa-facebook-f fa-stack-1x"></i>
                        </a>
                    </span>
                    <span className="fa-stack">
                        <a href="https://www.instagram.com/idatatrip" target="_blank" rel="noreferrer">
                            <i className="fas fa-circle fa-stack-2x"></i>
                            <i className="fab fa-instagram fa-stack-1x"></i>
                        </a>
                    </span>
                    <span className="fa-stack">
                        <a href="https://www.linkedin.com/company/datatrip/" target="_blank" rel="noreferrer">
                            <i className="fas fa-circle fa-stack-2x"></i>
                            <i className="fab fa-linkedin fa-stack-1x"></i>
                        </a>
                    </span>
                </span>
            </div>
        </nav>

    )

}
