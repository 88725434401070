/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function Header(props) {

    return (
        <header id="header" className="header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-container">
                            <div className="countdown">
                                <span id="clock"></span>
                            </div>
                            {props.data.title ? <h1>{props.data.title}</h1> : null}
                            {props.data.text ? <p className="p-large">{props.data.text}</p> : null}
                            {props.data.button ? <a className="btn-solid-lg page-scroll" href="#contact">{props.data.button}</a> : null}
                        </div>
                    </div>
                </div>
            </div>

        </header>
    )
}

/**
 *                 
 * import slider1 from './assets/slider-datatrip-1.jpg'
import slider2 from './assets/slider-datatrip-2.jpg'
import slider3 from './assets/slider-datatrip-3.jpg'
<div className="outer-container">
                    <div className="slider-container">
                        <div className="swiper-container image-slider-1">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide" >
                                    <img className="img-fluid" src={slider1} alt="alternative" />
                                </div>
                                <div className="swiper-slide">
                                    <img className="img-fluid" src={slider2} alt="alternative" />
                                </div>
                                <div className="swiper-slide">
                                    <img className="img-fluid" src={slider3} alt="alternative" />
                                </div>
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
 */