export default function BoxCards(props) {
    return (
        <div id="services" className="cards secondary-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2>{props.data.title ? props.data.title : null}</h2>
                        <p className="p-heading">{props.data.text ? props.data.text : null}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {props.data.box.map((box, index) =>
                            <div className="card" key={index}>
                                <div className="card-image">
                                    <i className={box.icon}></i>
                                </div>
                                <div className="card-body">
                                    <h4 className="card-title">{box.title}</h4>
                                    <p>{box.text}</p>
                                    {box.button ? <a className="btn-solid-lg page-scroll" href={box.link}>{box.button}</a> : null}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

    )
}
