import React from 'react'

export default function BoxContent(props) {
    return (
        <div id="description" className="ex-basic-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {
                            props.data.image ?
                                <div className="image-container-large">
                                    <img className="img-fluid" src="images/article-details-large.jpg" alt="alternative" />
                                </div>
                                : null
                        }

                        <div className="text-container">
                            {props.data.title ? <h3>{props.data.title}</h3> : null}
                            {props.data.text1 ? <p>{props.data.text1}</p> : null}
                            {props.data.itemsLeft ?
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="list-unstyled li-space-lg">
                                            {props.data.itemsLeft.map((item, index) =>
                                                <li className="media" key={index}>
                                                    <i className="fas fa-square"></i>
                                                    <div className="media-body">{item}</div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>

                                    <div className="col-md-6">
                                        <ul className="list-unstyled li-space-lg">
                                            {props.data.itemsRight.map((item, index) =>
                                                <li className="media" key={index}>
                                                    <i className="fas fa-square"></i>
                                                    <div className="media-body">{item}</div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div> : null}
                        </div>

                        {
                            props.data.text2 ?
                                <div className="text-container dark">
                                    <p className="testimonial-text">{props.data.text2}</p>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
