import React, { Component } from 'react'

export default class Copyright extends Component {
    render() {
        return (
            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="p-small">Copyright © 2023 <a href="https://www.idatatrip.com">idataTrip</a> - All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
