import { useTranslation } from 'react-i18next';

import Header from '../../Components/Header/Header';
import ProcessDataScience from '../../Components/ProcessDataScience/ProcessDataScience';
import BoxCards from '../../Components/BoxCards/BoxCards';
import Contact from '../../Components/Contact/Contact';
import BoxSliderImages from '../../Components/BoxSliderImages';

import DataSlider from './data/BoxSliderImages/dataSlider';
import DataHeader from './data/Header';
import DataBoxCards from './data/BoxCards';
import DataProcess from './data/Process';
import DataContact from './data/Contact'

const dataSlider = DataSlider();

export default function ProjectManager() {
    const { i18n } = useTranslation();

    return (
        <>
            <Header data={DataHeader(i18n.language)} />
            <BoxCards data={DataBoxCards(i18n.language)} />
            <BoxSliderImages data={dataSlider} />
            <ProcessDataScience data={DataProcess(i18n.language)} />
            <Contact data={DataContact(i18n.language)} />
        </>
    );
}