import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-col first">
                                <h5>idataTrip</h5>
                                <p className="p-small">Sabemos que quieres hacer crecer tu negocio y queremos ayudarte a conseguirlo.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-col second">
                                <h5>Talento Tech</h5>
                                <ul className="list-unstyled li-space-lg p-small">
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/tech-talent">Expertos Tech</a></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/tech-talent">Talento Tech</a></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/tech-talent">Developers</a></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-col third">
                                <h5>Métricas ágiles</h5>
                                <ul className="list-unstyled li-space-lg p-small">
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/agile-metrics">Optimiza tus Proyectos</a></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/agile-metrics">Medicion de Equipos Ágiles</a></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/agile-metrics">Importancia de las Métricas</a></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-col third">
                                <h5>Análisis UX</h5>
                                <ul className="list-unstyled li-space-lg p-small">
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/ux-analysis">Experiencia del Usuario</a></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/ux-analysis">Recomendaciones de UX </a></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a href="/ux-analysis">Mejora tu Sitio Web</a></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
