export default function ProcessDataScience(props) {
    return (
        <div id="process" className="basic-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2>{props.data.title}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <ul className="list-unstyled li-space-lg first">
                            {props.data.processItems1.map((data, index) => (
                                <li className="media" key={index}>
                                    <i className="bullet">{data.id}</i>
                                    <div className="media-body">
                                        <h4>{data.title}</h4>
                                        <p>{data.text}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <ul className="list-unstyled li-space-lg second">
                            {props.data.processItems2.map((data, index) => (
                                <li className="media" key={index}>
                                    <i className="bullet">{data.id}</i>
                                    <div className="media-body">
                                        <h4>{data.title}</h4>
                                        <p>{data.text}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

