import { useTranslation } from 'react-i18next';

import Header from '../../Components/Header/Header';
import ProcessDataScience from '../../Components/ProcessDataScience/ProcessDataScience';
import Contact from '../../Components/Contact/Contact';


import DataHeader from './data/Header';
import DataContact from './data/Contact'
import DataProcess from './data/Process';

function Tourism() {
    const { i18n } = useTranslation();

    return (
        <>
            <Header data={DataHeader(i18n.language)} />
            <ProcessDataScience data={DataProcess(i18n.language)} />
            <Contact data={DataContact(i18n.language)} />
        </>
    )
}

export default Tourism