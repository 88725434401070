import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    es: {
        translation: {
            what: 'Que Hacemos?',
            who: 'Como lo Hacemos?',
            techTalen: 'Talento Tech',
            projectManager: 'IT Project Manager',
            uxAnalysis: 'Analisis UX',
            agileMtrics: 'Metricas Agiles',
            tourism: 'Turismo',
            contact: 'CONTACTANOS',
        },
    },
    en: {
        translation: {
            what: 'What do we do?',
            who: 'Como lo Hacemos?',
            techTalen: 'Tech Talent',

            projectManager: 'IT Project Manager',
            uxAnalysis: 'UX analysis',
            agileMtrics: 'Agile Metrics',
            tourism: 'Tourism',
            contact: 'CONTACT US',
        },
    },
    fr: {
        translation: {
            what: 'Que faisons nous?',
            who: 'Como lo Hacemos?',
            techTalen: 'Talents Tech',

            projectManager: 'IT Project Manager',
            uxAnalysis: 'Analyse UX',
            agileMtrics: 'Métriques Agiles',
            tourism: 'Tourisme',
            contact: 'CONTACTEZ-NOUS',
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'es',

    keySeparator: false,

    interpolation: {
        escapeValue: false,
    },
});

export default i18n;