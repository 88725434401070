import { useTranslation } from 'react-i18next';

import Header from '../../Components/Header/Header';
import Contact from '../../Components/Contact/Contact';
import BoxContent from '../../Components/BoxContent';
import BoxCards from '../../Components/BoxCards/BoxCards';
import ProcessDataScience from '../../Components/ProcessDataScience/ProcessDataScience';

import DataHeader from './data/Header';
import DataBoxContent from './data/BoxContent';
import DataContact from './data/Contact'
import DataBoxCards from './data/BoxCards';
import DataProcess from './data/Process';




export default function TechTalent() {
    const { i18n } = useTranslation();

    return (
        <>
            <Header data={DataHeader(i18n.language)} />
            <BoxCards data={DataBoxCards(i18n.language)} />
            <BoxContent data={DataBoxContent(i18n.language)} />
            <ProcessDataScience data={DataProcess(i18n.language)} />
            <Contact data={DataContact(i18n.language)} />
        </>
    );
}