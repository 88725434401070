import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function BoxSliderImages(props) {
    return (
        <div className="slider-1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <p className="p-small">{props.data.title}</p>
                        <div className="slider-container">
                            <div className="swiper-container image-slider-2">
                                <div className="swiper-wrapper">
                                    <Stack direction="row" spacing={2}>
                                        {props.data.slide.map((box, index) =>
                                            <Item key={index}>
                                                <div className="swiper-slide" key={index}>
                                                    <img className="img-fluid" src={box.img} alt={box.alt} />
                                                </div>
                                            </Item>
                                        )}
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );

}
